<template>
  <li class="max-w-full border mb-3 select-none"
  style="border-radius: 8px;">
    <div @click="handleRedirect" class="flex items-start px-2 py-2 cursor-pointer">
      <a-initials :name="restaurant.name" :src="restaurant.profile_picture" :desing-class="'w-12 h-12'"></a-initials>

      <div class="flex-grow flex flex-col items-start text-left flex-wrap pl-2">
        <p v-if="restaurant?.name" class="text-lg font-light capitalize text-black" style="word-break: break-all;">
          {{ restaurant.name }}
        </p>
        <p v-if="restaurant?.ig_username" class="font-light" style="word-break: break-all;">
          {{ '@' + restaurant.ig_username }}
        </p>
        <div v-if="restaurant?.address" class="text-sm font-light">
          <i class="fas fa-map-marker-alt pr-1" />
          <p class="inline-block">{{ restaurant.address }}</p>
          <p v-if="restaurant.city" class="inline-block">{{ `, ${restaurant.city}` }}</p>
          <p v-if="restaurant.state" class="inline-block">{{ `, ${restaurant.state}` }}</p>
        </div>
      </div>

      <Rating :modelValue="restaurant.rating" :stars="5" :cancel="false" class="flex-shrink-0" :readonly="true"/>
    </div>

    <div class="border-t text-right px-4 py-2">
      <button
        @click="markInterest"
        class="py-1 border rounded-full inline-block text-center text-white w-24"
        :class="`${restaurant?.foodie_is_interested ? 'bg-gray' : 'bg-primary'}`">

        <span v-if="loading">
          <a-loader class="w-6 h-6 text-center block mx-auto" :color="'text-white'"  />
        </span>
        <span v-else>
          <p>{{ restaurant?.foodie_is_interested ? 'Retirar' : 'Colab'  }}</p>
        </span>
      </button>
    </div>

    <a-alert
      hide-cancel
      :show="modals.interested"
      @confirm="confirmModal"
      :confirm-class="'text-center block mx-auto bg-primary rounded-full text-white p-button-custom'">
      <div>
        <h1 class="text-center text-lg">Has notificado a <span class="capitalize">{{ restaurant?.name }}</span> tu intención por colaborar</h1>
      </div>
      <div>
        <h1 class="text-center text-lg mt-5">Te notificaremos si el restaurante genera una solicitud de colaboración</h1>
      </div>
    </a-alert>

    <a-alert
      hide-cancel
      :show="modals.uninterested"
      @confirm="removeInterest"
      @cancel="modals.uninterested = !modals.uninterested">
      <div>
        <h2 class="text-xl text-red text-center">Alerta</h2>
        <h1 class="text-lg">Si retiras tu solicitud, no podrás volver a pedirla en 90 días</h1>
      </div>
    </a-alert>

    <a-alert
      hide-cancel
      :show="modals.error"
      @confirm="modals.error = !modals.error">
      <div>
        <h2 class="text-xl text-red text-center">Error</h2>
        <h1 class="text-lg">{{ errorMessage }}</h1>
        <h1 class="text-lg">{{ 'Dias restantes: ' + errorTime }}</h1>
      </div>
    </a-alert>
  </li>
</template>

<script>
import Rating from 'primevue/rating'
import { useErrors } from '../../../../hooks'

export default {
  props: {
    restaurant: { required: true, type: Object },
    foodie: { required: true, type: Number }
  },
  emits: ['fetch'],
  data: () => ({
    loading: false,
    rating: '',
    modals: {
      interested: false,
      uninterested: false,
      error: false
    },
    errorMessage: '',
    errorTime: 0
  }),
  components: {
    Rating
  },
  methods: {
    // markInterested () {
    //   const action = this.restaurant.foodie_is_interested ? 'detach' : 'attach'
    //   this.loading = !this.loading
    //   this.$repository.foodies
    //     .markInterested(this.foodie, {
    //       [action]: [this.restaurant.id]
    //     })
    //     .then(({ data }) => {
    //       this.$emit(action, data)
    //     })
    //     .finally(() => {
    //       this.loading = !this.loading
    //       if (action === 'attach') {
    //         this.modal = !this.modal
    //       }
    //     })
    // },
    confirmModal () {
      this.modals.interested = !this.modals.interested
      this.$emit('fetch')
    },
    markInterest () {
      // const action = this.restaurant.foodie_is_interested ? 'detach' : 'attach'
      if (this.restaurant.foodie_is_interested) {
        this.modals.uninterested = !this.modals.uninterested
      } else {
        this.loading = !this.loading
        this.$repository.foodies
          .markInterested(this.foodie, { restaurant_id: this.restaurant.id })
          .then(() => {
            this.modals.interested = true
          })
          .catch(({ response }) => {
            this.modals.error = true
            this.errorMessage = useErrors(response?.data?.error_code).message
            console.log(response)
            // console.log(reponse.data.payload.diff_in_days)
            this.errorTime = response.data.payload.diff_in_days
          })
          .finally(() => {
            this.loading = !this.loading
          })
      }
    },
    removeInterest () {
      this.loading = !this.loading
      this.$repository.foodies
        .unmarkInterested(this.foodie, this.restaurant.id)
        .then(() => {
          this.modals.uninterested = false
          this.$emit('fetch')
        })
        .catch(({ response }) => {
          this.modals.uninterested = false
          this.modals.error = true
          this.errorMessage = useErrors(response?.data?.error_code).message
          this.errorTime = response.data.payload.diff_in_days
        })
        .finally(() => {
          this.loading = !this.loading
          // testing
        })
    },
    handleRedirect () {
      this.$router.push({ name: 'restaurants.show', params: { id: this.restaurant.id } })
    }
  }
}
</script>

<style scoped>
.p-button-custom{
  padding: 3px 5px !important;
}
</style>
